import { Turbo } from "@hotwired/turbo-rails";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/dialog";

import { VariableType } from "../../view/queries/controls/schema";
import { LuPencil, LuPlus } from "react-icons/lu";

interface Props {
  values: {
    report_id: string;
    variables: Omit<VariableType, "value">[];
  };
  onOpenChange: (open: boolean) => void;
  openDialog: boolean;
}

export const VariablesDialog = ({
  values,
  onOpenChange,
  openDialog,
}: Props) => {
  const handleViewVariable = (variable: Omit<VariableType, "value">) => {
    onOpenChange(false);
    Turbo.visit(
      `../custom_reports/${values.report_id}/variables/${variable.id}`
    );
  };

  const handleEditVariable = (variable: Omit<VariableType, "value">) => {
    onOpenChange(false);
    Turbo.visit(
      `../custom_reports/${values.report_id}/variables/${variable.id}/edit`
    );
  };

  const handleAddVariable = () => {
    onOpenChange(false);
    Turbo.visit(`../custom_reports/${values.report_id}/variables/new`);
  };

  return (
    <Dialog open={openDialog} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Variables</DialogTitle>
          <DialogDescription>Change your query variables.</DialogDescription>
        </DialogHeader>

        <div className="rounded border overflow-y-auto max-h-80">
          {values?.variables?.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="text-xs text-gray-500 text-left *:font-medium *:px-6 *:py-3 *:sticky *:top-0 *:bg-gray-50">
                  <th scope="col">Display Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Variable Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {values?.variables?.map((variable) => (
                  <tr
                    key={variable.id}
                    className="hover:bg-gray-50 *:px-6 *:py-4 *:whitespace-nowrap *:text-sm"
                  >
                    <td className="font-medium text-gray-900">
                      {variable.display_name}
                    </td>
                    <td className="text-gray-500">{variable.variable_type}</td>
                    <td className="text-gray-500">{variable.name}</td>
                    <td className="flex flex-row gap-1 text-right font-medium">
                      <button
                        type="button"
                        className="btn w-full"
                        onClick={() => handleViewVariable(variable)}
                      >
                        View
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => handleEditVariable(variable)}
                      >
                        <LuPencil className="w-4 h-4 text-gray-500" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoVariables reportId={values.report_id} />
          )}
        </div>

        {values?.variables?.length > 0 ? (
          <DialogFooter className="flex flex-row gap-2 !justify-between w-full">
            <button type="button" className="btn" onClick={handleAddVariable}>
              Add variable
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onOpenChange(false)}
            >
              Done
            </button>
          </DialogFooter>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

const NoVariables = ({ reportId }: { reportId: string }) => {
  const handleAddVariable = () => {
    Turbo.visit(`../custom_reports/${reportId}/variables/new`);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-6 text-center p-12">
      <span className="block p-2 rounded-md w-fit bg-gray-50">
        <LuPlus className="w-8 h-8 text-gray-500" />
      </span>

      <div className="flex flex-col gap-1 items-center max-w-xs">
        <h3 className="text-lg font-medium text-gray-900">
          No Variables Found
        </h3>
        <p className="text-sm text-gray-500 pb-8">
          Get started by adding your first variable to this custom report.
        </p>
        <button
          type="button"
          className="btn btn-primary inline-flex items-center w-fit"
          onClick={handleAddVariable}
        >
          Add Your First Variable
        </button>
      </div>
    </div>
  );
};
