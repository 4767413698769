import { Switch } from "~/components/switch";
import { CustomReport } from "../types";
import { useFormState } from "./hooks/useFormState";

export const ReportHeader = ({
  report,
  isRefreshable,
}: {
  report: CustomReport;
  isRefreshable: boolean;
}) => {
  const { formState, handleSwitch } = useFormState(report);

  return (
    <div className="flex items-start justify-between">
      <h2 className="text-base font-medium">Report Details</h2>
      {report.can_edit && (
        <Switch
          checked={formState.enabled}
          disabled={!isRefreshable || !report.google_sheet_url}
          onCheckedChange={handleSwitch}
        />
      )}
    </div>
  );
};

export const ReportDetails = ({ report }: { report: CustomReport }) => (
  <table className="w-full text-sm border-separate table-fixed border-spacing-y-2">
    <tbody>
      <tr className="align-baseline">
        <td className="text-gray-500 w-[180px]">Name</td>
        <td>{report.name}</td>
      </tr>
      <tr className="align-baseline">
        <td className="text-gray-500">Description</td>
        <td>{report.description}</td>
      </tr>
    </tbody>
  </table>
);
