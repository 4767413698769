import { z } from "zod";

export type DateRangeValue = {
  start_date: string;
  end_date: string;
};

export type LookbackPeriodValue = {
  number: number;
  period: "day" | "month" | "year";
};

export type DateValue = {
  date: string;
};

export type VariableControlType = "DATE" | "DATE_RANGE" | "LOOKBACK_PERIOD";

export type VariableValueMap = {
  DATE: DateValue;
  DATE_RANGE: DateRangeValue;
  LOOKBACK_PERIOD: LookbackPeriodValue;
};

export type VariableType<T extends VariableControlType = VariableControlType> =
  {
    id: string;
    name: string;
    display_name: string;
    variable_type: T;
    value: VariableValueMap[T];
  };

const dateSchema = z.object({ date: z.string() });

const dateRangeSchema = z
  .object({
    start_date: z.string(),
    end_date: z.string(),
  })
  .refine((data) => new Date(data.start_date) <= new Date(data.end_date), {
    message: "End date must be after or equal to start date",
    path: ["end_date"],
  });

const lookbackPeriodSchema = z.object({
  number: z.number().positive(),
  period: z.enum(["day", "month", "year"]),
});

export type ControlSchema =
  | typeof dateSchema
  | typeof dateRangeSchema
  | typeof lookbackPeriodSchema;

// Define the shape of the entire form schema
export type FormSchema = z.ZodObject<Record<string, ControlSchema>>;

export const createVariableSchema = (controls: VariableType[]) => {
  const schemaObject: Record<string, ControlSchema> = {};

  controls?.forEach((control) => {
    switch (control.variable_type) {
      case "DATE":
        schemaObject[control.id] = dateSchema;
        break;
      case "DATE_RANGE":
        schemaObject[control.id] = dateRangeSchema;
        break;
      case "LOOKBACK_PERIOD":
        schemaObject[control.id] = lookbackPeriodSchema;
        break;
    }
  });

  const schema = z.object(schemaObject) as FormSchema;
  return { schema, zodType: schema };
};
