import { Application } from "@hotwired/stimulus";
import Notification from "stimulus-notification";
import Dropdown from "stimulus-dropdown";
import Popover from "stimulus-popover";

const application = Application.start();

application.register("notification", Notification);
application.register("dropdown", Dropdown);
application.register("popover", Popover);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
